import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"
import { ContentWrapper } from "../common"

const LogisticsWrapper = styled.div`
  background-color: ${theme.colors.darkerGray};
  color: #fff;
`

const Wrapper = styled(ContentWrapper)``

const IngressWrapper = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
  max-width: ${theme.screen.desktopContent}px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  border-bottom: 1px solid $tertiary;
  padding-bottom: 30px;

  > p {
    line-height: 1.813;
  }
`

const ListWrapper = styled.ol`
  counter-reset: logistics-counter;
`

const ListItemWrapper = styled.li`
  list-style-type: decimal;
  list-style-position: outside;
  padding: 10px 40px 10px 50px;
  margin: 10px auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-content: center;
  counter-increment: logistics-counter;
  position: relative;
  color: #fff;

  &:before {
    content: counter(logistics-counter);
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    top: 12px;
    font-size: 24px;
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.darkerGray};
    font-weight: 600;
    border-radius: 60px;
    width: 42px;
    height: 42px;
  }
`

const ListItemTitle = styled.div`
  font-weight: 600;
  line-height: 1.786;
`

const ListItemDescription = styled.div`
  line-height: 1.786;
`

const HighlightWrapper = styled.div`
  color: #333;
  margin: 10px auto 20px;
  max-width: ${theme.screen.desktopContent}px;
`

const HighlightTitleWrapper = styled.div`
  color: #fff;
`

const HighlightPhases = styled.div`
  padding: 0 30px;
`

const LogisticsSection = () => (
  <LogisticsWrapper id="logistiikka">
    <Wrapper>
      <h1>Tee logistiikan ulkoistuspäätös</h1>
      <IngressWrapper>
        <p>
          Logistiikan ulkoistamisella Pääkaupunkiseudun Tehopalvelulle tarjoamme
          teille mahdollisuuden 10–30% kustannussäästöihin.
        </p>
        <p>
          Logistiikasta voi saada jopa yli neljäkymmentä prosenttia kiinteistä
          kustannuksista muuttuviksi ja samalla alennettua kokonaiskustannuksia.
          Olemme teille se kumppani, joka jakaa kuormitusta. Jos volyyminne
          laskee äkillisesti, toiminta pitää sopeuttaa nopeasti. Samoin jos
          kysyntä kasvaa yllättäen, on hyvä varmistaa toimitusvarmuus ilman
          ylityökustannuksia. Meillä on kyky henkilöresurssien siirtelyyn
          volyymien mukaisesti. Logistiikkakumppanina omaamme merkittävän
          henkilöstöpoolin ja näin ollen pystymme ohjaamaan nopeasti työpanoksen
          sinne, missä on kysyntää.
        </p>
      </IngressWrapper>
      <HighlightWrapper>
        <HighlightTitleWrapper>
          <h1>Varaston ja toimittamisen työvaiheet</h1>
          <p style={{ textAlign: "center", lineHeight: "1.857" }}>
            Olemme verkkokauppasi paras kumppani varasto- ja
            logistiikkapalveluissa.
          </p>
          <p style={{ textAlign: "center", lineHeight: "1.857" }}>
            Otamme vastuullemme kaikki tuotteidesi varastointiin, keräilyyn ja
            toimittamiseen liittyvät tehtävät. Voit rauhassa keskittyä
            verkkokaupan kasvuun ja myynnin tukemiseen.
          </p>
        </HighlightTitleWrapper>
        <HighlightPhases>
          <ListWrapper>
            <ListItemWrapper>
              <ListItemTitle>Vastaanotto</ListItemTitle>
              <ListItemDescription>
                Vastaanotto tapahtuu Työpöydässä tehdyn ostotilauksen
                perusteella. Tarkastamme saapuneen erän, ja siirrämme tuotteet
                hyllyihin. Saldot päivittyvät Työpöytään.
              </ListItemDescription>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItemTitle>Varastointi</ListItemTitle>
              <ListItemDescription>
                Tuotteet varastoidaan keräilyreitin varrella oleville hyllyille.
              </ListItemDescription>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItemTitle>Keräily ja pakkaaminen</ListItemTitle>
              <ListItemDescription>
                Työpöytä välittää varastolle tiedon keräiltävistä tuotteista.
                Tuotteet keräillään ja pakataan tilausten mukaisesti, jonka
                jälkeen ne ovat valmiita toimitukseen. Palvelu sisältää
                lähetyslistat ja vakioidut lähetyspussit ja -laatikot.
              </ListItemDescription>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItemTitle>Lähetysten toimittaminen tilaajille</ListItemTitle>
              <ListItemDescription>
                Toimitus tapahtuu tilauksessa valitun toimitustavan ja
                ajankohdan mukaisesti joko tilaajalle kotiin, toimipaikkaan tai
                tilaajan valitsemaan noutopisteeseen. Palvelumme sisältää
                lähetystunnuksen välittämisen SMS-viestillä vastaanottajalle ja
                paketin toimittamisen perille. Työpöydälle välittyy tieto
                lähetystunnuksesta ja vastaanottokuittauksesta.
              </ListItemDescription>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItemTitle>Palautusten käsittely</ListItemTitle>
              <ListItemDescription>
                Rekisteröimme palautukset heti niiden saavuttua. Tarkastamme
                tuotteet, ja otamme myyntikuntoiset tuotteet uudelleen myyntiin.
                Työpöytä välittää palautuksiin liittyvän tiedon asiakkaamme
                järjestelmään.
              </ListItemDescription>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItemTitle>Asiakaspalvelu</ListItemTitle>
              <ListItemDescription>
                Asiakaspalvelumme tavoittaa puhelimitse, sähköpostilla ja
                Työpöydän palvelupyynnöillä arkisin klo 8-16 välillä ja
                päivystäjämme päivystävät 24h.
              </ListItemDescription>
            </ListItemWrapper>
          </ListWrapper>
        </HighlightPhases>
      </HighlightWrapper>
    </Wrapper>
  </LogisticsWrapper>
)

export default LogisticsSection
