import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroSection from "../components/sections/hero"
import ServicesSection from "../components/sections/services"
import LogisticsSection from "../components/sections/logistics"
import ContactInformationSection from "../components/sections/contact-information"
import ContactFormSection from "../components/sections/contact-form"

const IndexPage = () => (
  <Layout>
    <SEO title="Pääkaupunkiseudun Tehohuolto Oy" />
    <HeroSection />
    <ServicesSection />
    <LogisticsSection />
    <ContactInformationSection />
    <ContactFormSection />
  </Layout>
)

export default IndexPage
