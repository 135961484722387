import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import theme from "../../utils/theme"
import { Grid, Column, ContentWrapper } from "../common"
import oivaReport from '../../images/oiva-raportti.jpg'

const ContactInformationWrapper = styled.div``

const Wrapper = styled(ContentWrapper)``

const ColumnWrapper = styled(Column)`
  max-width: 30%;

  @media (min-width: ${theme.screen.tablet}px) {
    &.contact {
      text-align: right;
    }
  }

  @media (max-width: ${theme.screen.tablet}px) {
    text-align: center;
    max-width: 100%;
  }
`

const LinkWrapper = styled.a`
  font-size: 20px;
  display: block;

  &:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 15px;
    font-size: 16px;
  }
`

const AdditionalWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`

const BuyersResponsibility = styled(Grid)`
  text-align: center;
  margin-top: 30px;
  justify-content: space-between;
  max-width: 600px;
  flex-flow: row wrap;
  align-content: center;
  margin-left: auto;
  margin-right: auto;

  > * {
    align-self: center;
  }
`

const OivaReport = styled.div`
  text-align: center;
  margin-top: 60px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`

const ContactInformationSection = () => {
	const data = useStaticQuery(graphql`
    query {
      tilaajavastuuImage: file(relativePath: { eq: "tilaajavastuu.jpg" }) {
        childImageSharp {
          fixed(width: 210, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      luotettavaImage: file(relativePath: { eq: "luotettava-kumppani.jpg" }) {
        childImageSharp {
          fixed(width: 160, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      aaaImage: file(relativePath: { eq: "aaa.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      kkImage: file(relativePath: { eq: "jasenlogo.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      oivaImage: file(relativePath: { eq: "oiva.jpg" }) {
        childImageSharp {
          fixed(width: 115) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

	const { tilaajavastuuImage, luotettavaImage, aaaImage, oivaImage, kkImage } = data

	return (
		<ContactInformationWrapper id="yhteystiedot">
			<Wrapper>
				<h1>Yhteystiedot</h1>
				<Grid>
					<ColumnWrapper className="contact">
						<h2 id="asiakaspalvelu">Asiakaspalvelu</h2>
						<p>
							Asiakaspalvelu 24h
							<LinkWrapper href="tel:041 749 5314">041 749 5314</LinkWrapper>
						</p>
						<p>
							Hallinto Marko Aarnikallio (KTJ®)
							<LinkWrapper href="tel:040 578 2526">040 578 2526</LinkWrapper>
						</p>
					</ColumnWrapper>
					<ColumnWrapper className="billing">
						<h2 id="laskutus">Laskutus</h2>
						<p>Pääkaupunkiseudun Tehopalvelu Oy</p>
						<p>Körsnäsintie 13 B, 00890 Helsinki</p>
					</ColumnWrapper>
				</Grid>
				<AdditionalWrapper className="contact-additional">
					<p>Tai jätä yhteydenottopyyntö alla olevalla lomakkeella</p>
				</AdditionalWrapper>
				<BuyersResponsibility className="tilaaja-vastuu">
					<Img fixed={tilaajavastuuImage.childImageSharp.fixed} alt="tilaaja-vastuu" />
					<Img fixed={luotettavaImage.childImageSharp.fixed} alt="luotettava-kumppani" />
					<Img fixed={aaaImage.childImageSharp.fixed} className="aaa-creditability" alt="AAA korkein luottoluokitus" />
					<Img fixed={kkImage.childImageSharp.fixed} alt="Kauppakamari" />
				</BuyersResponsibility>
				<OivaReport className="oiva-raportti">
					<a href={oivaReport} target="_blank" rel="noreferrer">
						<Img fixed={oivaImage.childImageSharp.fixed} alt="Oiva sinetti" />
						<p>Elintarvikeviraston Oiva-raportin voit lukea tästä.</p>
					</a>
				</OivaReport>
			</Wrapper>
		</ContactInformationWrapper>
	)
}

export default ContactInformationSection
