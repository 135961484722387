import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import theme from "../../utils/theme"
import { Grid, Column, ContentWrapper } from "../common"

const HeroWrapper = styled.div`
  background: url(${props => props.bgImage.src}) no-repeat center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vw;
  min-height: 474px;
  max-height: 620px;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;

  @media (min-width: ${theme.screen.narrow}px) {
    background-position-y: -50px;
  }
`

const HeroInner = styled(ContentWrapper)`
  position: absolute;
  bottom: -40px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px 30px 10px;
  left: 0;
  right: 0;
  box-shadow: 0 10px 25px -15px #333;
  border-radius: 4px;

  @media (max-width: ${theme.screen.narrow}px) {
    bottom: 0;
    box-shadow: none;
    border-radius: 0;
  }
`

const GridWrapper = styled(Grid)``

const LogoWithoutTextWrapper = styled(Column)`
  flex: 1 1 20%;
  text-align: center;
  max-width: 135px;

  @media (min-width: ${theme.screen.narrow}px) {
    padding-left: 40px;
  }

  @media (max-width: ${theme.screen.tablet}px) {
    display: none;
  }
`

const IntroductionSection = styled(Column)`
  flex: 1 1 75%;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  text-align: center;

  p {
    line-height: 2;
  }

  @media (min-width: ${theme.screen.narrow}px) {
    padding-right: 120px;
  }

  @media (max-width: ${theme.screen.tablet}px) {
    font-size: 14px;

    p {
      line-height: 2;
    }
  }
`

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo-notext.png" }) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      bgImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fixed(width: 1600) {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)

  const { logoImage, bgImage } = data

  return (
    <HeroWrapper id="etusivu" bgImage={bgImage.childImageSharp.fixed}>
      <HeroInner>
        <GridWrapper>
          <LogoWithoutTextWrapper>
            <Img
              fluid={logoImage.childImageSharp.fluid}
              alt="Logo"
            />
          </LogoWithoutTextWrapper>
          <IntroductionSection>
            <p>
              Pääkaupunkiseudun Tehopalvelu Oy on suomalainen laadukkaita
              kiinteistöhoito-ja logistiikkaratkaisuja tuottava yritys. Meiltä
              hoituvat kaikki kiinteistöihin liittyvät työt siivouksesta
              remontteihin ja pihanhoitoon. Logistiikan saralla meillä on
              vuosien kokemus varastoinneista ja kuljetuksista. Henkilöstömme on
              koulutettua, ammattitaitoista ja motivoitunutta kohtaamaan
              arkipäivän haasteet.
            </p>
          </IntroductionSection>
        </GridWrapper>
      </HeroInner>
    </HeroWrapper>
  )
}

export default HeroSection
