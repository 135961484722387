import React from "react"
import styled from "styled-components"
import theme from "../../utils/theme"
import { Grid, Column, ContentWrapper } from "../common"

const ServicesWrapper = styled.div`
  background-color: ${theme.colors.darkerBlue};
  color: #fff;
`

const Wrapper = styled(ContentWrapper)`
  padding-top: 130px;

  @media (max-width: ${theme.screen.narrow}px) {
    padding-top: 30px;
  }
`

const IngressWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  border-bottom: 1px solid $tertiary;
  padding-bottom: 30px;

  > p {
    line-height: 1.722;
  }
`

const GridWrapper = styled(Grid)`
  @media (max-width: ${theme.screen.narrow}px) {
    flex-wrap: wrap;
  }
`

const ColumnWrapper = styled(Column)`
  flex: 1 1 25%;
  margin-bottom: 15px;

  @media (max-width: ${theme.screen.narrow}px) {
    width: calc(50% - 30px);
  }
`

const ListWrapper = styled.ul`
  padding-left: 20px;
`

const ListItemWrapper = styled.li`
  list-style-type: disc;
  line-height: 1.5;
`

const ServicesSection = () => (
  <ServicesWrapper id="palvelut">
    <Wrapper>
      <IngressWrapper>
        <p>
          Meiltä voit tilata ammattilaisen vaikka laittamaan mökkisi valmiiksi
          keväällä tai talvikuntoon syksyllä. Myös viikko ja kuukausisiivoukset
          kesäasuntoihin onnistuvat vaivatta.{" "}
        </p>
        <p>Huomioithan, että työn osuus on verovähennyskelpoista.</p>
      </IngressWrapper>
      <h1>Tarjoamme seuraavia palveluita</h1>
      <GridWrapper>
        <ColumnWrapper className="services">
          <h2 id="kiinteist-nhoitopalvelut">Kiinteistönhoitopalvelut</h2>
          <ListWrapper>
            <ListItemWrapper>Huolto- ja kunnossapitotyöt</ListItemWrapper>
            <ListItemWrapper>Valvonta</ListItemWrapper>
            <ListItemWrapper>
              Huoltopäivystyksemme palvelee 24 h
            </ListItemWrapper>
            <ListItemWrapper>Liputukset</ListItemWrapper>
            <ListItemWrapper>Ulkoalueiden kunnossapito</ListItemWrapper>
            <ListItemWrapper>
              Lumityöt, hiekoitukset, viheralueet
            </ListItemWrapper>
            <ListItemWrapper>Katujen pesu- ja lakaisupalvelut</ListItemWrapper>
            <ListItemWrapper>Ovenavaukset 24 / 7</ListItemWrapper>
            <ListItemWrapper>Kattolumien tiputukset</ListItemWrapper>
          </ListWrapper>
        </ColumnWrapper>
        <ColumnWrapper className="cleaning-services">
          <h2 id="siivoukset">Siivoukset</h2>
          <ListWrapper>
            <ListItemWrapper>Sopimussiivoukset</ListItemWrapper>
            <ListItemWrapper>Perussiivoukset / kotisiivoukset</ListItemWrapper>
            <ListItemWrapper>Ylläpitosiivoukset</ListItemWrapper>
            <ListItemWrapper>Vahaukset</ListItemWrapper>
            <ListItemWrapper>Ikkunoiden pesut</ListItemWrapper>
            <ListItemWrapper>
              Tuotantotila, puhdastila ja erikoistilasiivoukset
            </ListItemWrapper>
            <ListItemWrapper>Rasvaisten kohteiden siivoukset</ListItemWrapper>
          </ListWrapper>
        </ColumnWrapper>
        <ColumnWrapper className="logistics-services">
          <h2 id="logistiikkaratkaisut">Logistiikkaratkaisut</h2>
          <ListWrapper>
            <ListItemWrapper>Varastoinnit lavat / pientavara</ListItemWrapper>
            <ListItemWrapper>Keräily</ListItemWrapper>
            <ListItemWrapper>Pikatoimitukset</ListItemWrapper>
            <ListItemWrapper>Lääkekuljetukset</ListItemWrapper>
            <ListItemWrapper>
              Erikoiskuljetukset (Lämpötilasäädellyt /ADR)
            </ListItemWrapper>
          </ListWrapper>
        </ColumnWrapper>
        <ColumnWrapper className="other-services">
          <h2 id="muut-palvelut">Muut palvelut</h2>
          <ListWrapper>
            <ListItemWrapper>
              Remontit sekä rakennustyöt pienistä vaativiin
            </ListItemWrapper>
            <ListItemWrapper>Vaihtolavat / talkoolavat</ListItemWrapper>
          </ListWrapper>
        </ColumnWrapper>
      </GridWrapper>
    </Wrapper>
  </ServicesWrapper>
)

export default ServicesSection
