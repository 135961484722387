import React, { useState } from "react"
import styled, { css } from "styled-components"
import { formEncode } from "../../utils/functions"
import theme from "../../utils/theme"
import { ContentWrapper } from "../common"

const ContactFormWrapper = styled.div`
  background-color: ${theme.colors.darkerGray};
  color: #fff;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`

const Label = styled.label`
  flex: 1 1 39.333%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: right;
  line-height: 34px;

  &[for] {
    cursor: pointer;
  }

  @media (max-width: ${theme.screen.tablet}px) {
    text-align: left;
    display: block;
    padding: 0;
    max-width: 100%;
  }
`

const InputFieldStyles = css`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  flex: 1 1 61.666%;

  @media (max-width: ${theme.screen.tablet}px) {
    max-width: 100%;
    width: 90%;
  }
`

const TextInput = styled.input`
  ${InputFieldStyles}
`

const TextAreaInput = styled.textarea`
  ${InputFieldStyles}
`

const Button = styled.button`
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 900;
  font-family: ${theme.fonts.primary};
  border-radius: 4px;
  background-color: ${theme.colors.darkerBlue};
  color: #fff;
  cursor: pointer;
  border: 0 none;
  flex: 1 1 61.666%;
`

const SubmittingForm = styled.div`
  padding: 30px;
  font-size: 16px;
  text-align: center;
`

const initialState = {
  isNameValid: false,
  isEmailValid: false,
  isMessageValid: false,
  isValidationActive: false,
  name: "",
  email: "",
  phone: "",
  message: "",
  isSubmitted: false,
  isSubmitting: false,
}

const ContactFormSection = () => {
  const [formState, setFormState] = useState(initialState)

  const onValidateField = (validationField, value) =>
    setFormState({
      ...formState,
      [validationField]: !!value,
    })

  const onFieldChange = event =>
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })

  const onValidateName = event =>
    onValidateField("isNameValid", event.target.value)
  const onValidateEmail = event =>
    onValidateField("isEmailValid", event.target.value)
  const onValidateMessage = event =>
    onValidateField("isMessageValid", event.target.value)

  const onFormSubmit = async event => {
    event.preventDefault()
    if (
      formState.isNameValid &&
      formState.isEmailValid &&
      formState.isMessageValid
    ) {
      setFormState({
        ...formState,
        isSubmitting: true,
      })

      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formEncode({ "form-name": "contact-form", ...formState }),
      })

      setFormState({
        ...formState,
        isSubmitting: false,
        isSubmitted: true,
      })
    } else {
      setFormState({
        ...formState,
        isValidationActive: true,
      })
    }
  }

  const {
    isNameValid,
    isEmailValid,
    isMessageValid,
    isSubmitted,
    isSubmitting,
    isValidationActive,
  } = formState

  return (
    <ContactFormWrapper id="ota-yhteytta">
      <ContentWrapper>
        <h1>Ota yhteyttä</h1>
        {!isSubmitting && !isSubmitted && (
          <form
            method="post"
            netlify="true"
            name="contact-form"
            onSubmit={onFormSubmit}
          >
            <FormGroup>
              <p style={{ textAlign: "center" }}>
                Jätä yhteydenottopyyntö tai palautetta toiminnastamme alla
                olevalla lomakkeella
              </p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="name">Nimi</Label>
              <TextInput
                type="text"
                id="name"
                name="name"
                onChange={onFieldChange}
                onBlur={onValidateName}
              />
            </FormGroup>
            {isValidationActive && !isNameValid && (
              <FormGroup>
                <div className="alert alert-danger">
                  <i className="fas fa-exclamation-circle"></i> Nimi on
                  pakollinen tieto
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group">
              <Label htmlFor="email">Sähköposti</Label>
              <TextInput
                type="email"
                id="email"
                name="email"
                onChange={onFieldChange}
                onBlur={onValidateEmail}
              />
            </FormGroup>
            {isValidationActive && !isEmailValid && (
              <FormGroup>
                <div className="alert alert-danger">
                  <i className="fas fa-exclamation-circle"></i> Sähköposti on
                  pakollinen tieto
                </div>
              </FormGroup>
            )}
            <FormGroup>
              <Label htmlFor="phone">Puhelin</Label>
              <TextInput
                type="text"
                id="phone"
                name="phone"
                onChange={onFieldChange}
                placeholder="(valinnainen)"
              />
            </FormGroup>
            <FormGroup>
              <Label>Viesti</Label>
              <TextAreaInput
                name="message"
                rows="4"
                onBlur={onValidateMessage}
                onChange={onFieldChange}
              ></TextAreaInput>
            </FormGroup>
            {isValidationActive && !isMessageValid && (
              <FormGroup>
                <div className="alert alert-danger">
                  <i className="fas fa-exclamation-circle"></i> Viesti on
                  pakollinen tieto
                </div>
              </FormGroup>
            )}
            <FormGroup>
              <Label />
              <Button type="submit">Lähetä</Button>
            </FormGroup>
          </form>
        )}

        {isSubmitted && (
          <SubmittingForm>
            <div className="alert alert-success">Kiitos! Lomake lähetetty.</div>
          </SubmittingForm>
        )}
      </ContentWrapper>
    </ContactFormWrapper>
  )
}

export default ContactFormSection
